// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "typeface-nunito"
import "typeface-alegreya"

export const onRouteUpdate = ({ location }) => {
  if (location.pathname === '/landing/') {
    document.documentElement.style.overflow = 'hidden'; // Hide scrollbar
  } else {
    document.documentElement.style.overflow = 'auto';
  }
};